<template>
  <div>
    <validation-observer ref="usuarioGenerico">
      <b-form>

        <b-card>
          <b-overlay
            :show="isWorking"
            :rounded="overlayConfig.rounded"
            :variant="overlayConfig.variant"
            :opacity="overlayConfig.opacity"
            :blur="overlayConfig.blur"
          >
            <template #overlay>
              <div v-if="isWorking">
                <div style="text-align: center">
                  <span class="spinner-border " />
                </div>
                <div style="text-align: center">
                  <small>{{ overlayConfig.message }}</small>
                </div>
              </div>
            </template>
            <b-row>
              <b-col md="4">
                <b-form-group
                  id="frmApellidoUsuario"
                  label="Apellido"
                >
                  <validation-provider
                    name="apellido"
                    rules="required|max:50"
                  >
                    <div slot-scope="{ errors, failedRules }">
                      <b-form-input
                        id="txtApellidoUsuario"
                        v-model="usuarioDto.apellido"
                        :state="errors.length > 0 ? false:null"
                        autocomplete="off"
                        :disabled="!isEditable"
                      />
                      <small
                        v-if="failedRules.required"
                        class="text-danger"
                      >Apellido obligatorio.</small>
                      <small
                        v-if="failedRules.max"
                        class="text-danger"
                      >Máximo 50 caracteres.</small>
                    </div>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col md="4">
                <b-form-group
                  id="frmNombreUsuario"
                  label="Nombre"
                >
                  <validation-provider
                    name="nombre"
                    rules="required|max:50"
                  >
                    <div slot-scope="{ errors, failedRules }">
                      <b-form-input
                        id="txtNombreUsuario"
                        v-model="usuarioDto.nombre"
                        :state="errors.length > 0 ? false:null"
                        autocomplete="off"
                        :disabled="!isEditable"
                      />
                      <small
                        v-if="failedRules.required"
                        class="text-danger"
                      >Nombre obligatorio.</small>
                      <small
                        v-if="failedRules.max"
                        class="text-danger"
                      >Máximo 50 caracteres.</small>
                    </div>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col md="4">
                <b-form-group
                  id="frmCuilUsuario"
                  label="C.U.I.L."
                >
                  <validation-provider
                    name="cuil"
                    rules="required|min:11"
                  >
                    <div slot-scope="{ errors, failedRules }">
                      <!--<b-form-input
                        id="txtCuilUsuario"
                        v-model="usuarioDto.cuil"
                        maxlength="11"
                        :state="errors.length > 0 ? false:null"
                        :disabled="!isEditable"
                      />-->
                      <cleave
                        id="block"
                        v-model="usuarioDto.cuil"
                        class="form-control"
                        :state="errors.length > 0 ? false:null"
                        :raw="true"
                        :options="options.customDelimiter"
                        autocomplete="off"
                        :disabled="!isEditable"
                        placeholder="xx-xxxxxxxx-x"
                      />
                      <!--<small
                        v-if="failedRules.required"
                        class="text-danger"
                      >C.U.I.L. obligatorio.</small>-->
                      <small
                        v-if="failedRules.required"
                        class="text-danger"
                      >C.U.I.L. obligatorio.
                      </small>
                      <small
                        v-if="failedRules.min"
                        class="text-danger"
                      >C.U.I.L. incompleto.
                      </small>
                    </div>
                  </validation-provider>
                </b-form-group>
              </b-col>
            </b-row>
            <b-row>
              <b-col md="4">
                <b-form-group
                  id="frmEmailUsuario"
                  label="Correo"
                >
                  <validation-provider
                    name="email"
                    rules="required|email"
                  >
                    <div slot-scope="{ errors, failedRules }">
                      <b-form-input
                        id="txtEmailUsuario"
                        v-model="usuarioDto.email"
                        class="form-control"
                        maxlength="50"
                        :state="errors.length > 0 ? false:null"
                        autocomplete="off"
                        :disabled="!isEditable"
                      />
                      <small
                        v-if="failedRules.required"
                        class="text-danger"
                      >Correo obligatorio.
                      </small>
                      <small
                        v-if="failedRules.email"
                        class="text-danger"
                      >Ingrese un correo válido.
                      </small>
                    </div>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col md="4">
                <b-form-group
                  id="chkActivo"
                  title="Estado en este sistema (Activo o Inactivo)"
                  label="Estado"
                >
                  <b-form-select
                    id="selectUsuarioEstado"
                    v-model="usuarioDto.usuarioEstado.id"
                    :options="usuarioEstadosDB"
                    value-field="id"
                    text-field="nombre"
                    :disabled="!isEditable"
                  />
                </b-form-group>
              </b-col>
              <b-col md="4">
                <b-form-group
                  id="frmDelegacionTable"
                  label="Delegación"
                >
                  <validation-provider
                    name="delegaciones"
                    rules="required"
                  >
                    <div slot-scope="{ errors, failedRules }">
                      <b-form-select
                        id="selectDelegaciones"
                        v-model="usuarioDto.delegacion.id"
                        :options="listaDelegaciones"
                        :state="errors.length > 0 ? false:null"
                        value-field="id"
                        text-field="nombre"
                        :disabled="!isEditable"
                      />
                      <small
                        v-if="failedRules.required"
                        class="text-danger"
                      >Delegación obligatoria.</small>
                    </div>
                  </validation-provider>
                </b-form-group>
              </b-col>
            </b-row>
            <hr>
            <b-row>
              <b-col md="5">
                <b-form-group
                  id="frmRolesTable"
                  label="Roles"
                />
                <b-table
                  borderless
                  no-border-collapse
                  sticky-header
                  small
                  :items="listaRoles"
                  :fields="fieldsRolTable"
                >
                  <template #cell(check)="row">
                    <b-form-checkbox
                      v-model="row.item.selected"
                      class="custom-control-success"
                      name="check-button"
                      switch
                      :disabled="!isEditable"
                      @change="actualizarRol(row.item)"
                    >
                      <small class="switch-icon-left">
                        <feather-icon icon="CheckIcon" />
                      </small>
                    </b-form-checkbox>

                  </template>
                </b-table>
              </b-col>
            </b-row>
          </b-overlay>
        </b-card>
        <hr>
        <b-row>
          <div>
            <b-col md="12">
              <b-button
                id="btnBackUsuario"
                v-b-tooltip.hover
                title="Volver a listado usuario"
                variant="light"
                squared
                @click="()=>backUsuario()"
              >
                <feather-icon icon="ArrowLeftIcon" />
              </b-button>
            </b-col>
          </div>
          <div class="align-self-end ml-auto">
            <b-col md="12">
              <b-button
                v-if="permisosIns.includes(nameModulo)"
                id="btnSaveUsuDirector"
                v-b-tooltip.hover
                variant="primary"
                squared
                title="Guardar usuario"
                type="submit"
                :disabled="isWorking || !isEditable"
                @click.prevent="validationFormUsuario"
              >
                <font-awesome-icon
                  v-if="!isWorking"
                  icon="fa-solid fa-floppy-disk"
                />
                <span
                  v-if="isWorking"
                  class="spinner-border spinner-border-sm"
                  aria-hidden="true"
                />
              </b-button>
            </b-col>
          </div>
        </b-row>
      </b-form>
    </validation-observer>
  </div>
</template>
<script>
// import BCardCode from '@core/components/b-card-code'
import { mapGetters } from 'vuex'
import { usuariosResource } from '@/services/usuariosResource'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import Cleave from 'vue-cleave-component'
import {
  required, confirmed, email, max,
  min,
} from '@validations'

export default {
  components: {
    // BCardCode,
    ValidationProvider,
    ValidationObserver,
    Cleave,
  },
  props: {
    usuario: {
      type: Object,
      required: false,
      default: undefined,
    },
    editarEnableDisable: {
      type: Boolean,
      required: false,
      default: undefined,
    },
    openMode: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      overlayConfig: {
        rounded: 'sm',
        variant: 'transparent',
        opacity: '0.8',
        blur: '5px',
      },
      selected: true,
      isWorking: false,
      required,
      confirmed,
      email,
      max,
      min,
      cargando: false,
      cargandoRoles: false,
      nameModulo: 'ABM_USUARIO',
      originalUsuarioDto: null,
      preloadedRoles: null,
      preloadedUsuarioEstado: null,
      preloadedDelegacion: null,
      usuarioDto: {
        id: null,
        apellido: '',
        nombre: '',
        cuil: '',
        email: null,
        fechaNacimiento: null,
        fechaIngreso: null,
        fechaBaja: null,
        baja: null,
        delegacion: {
          id: null,
          nombre: null,
        },
        usuarioEstado: {
          id: 1,
          nombre: null,
        },
        roles: [],
      },
      listaRoles: [],
      listaDelegaciones: [{ id: null, nombre: 'Seleccione...' }],
      ListaRolesAsignados: [],
      fieldsRolTable: [
        { key: 'Id', thClass: 'd-none', tdClass: 'd-none' },
        {
          key: 'nombre', label: 'Roles', thClass: 'd-none',
        },
        { key: 'check', label: 'check', thClass: 'd-none' },
        { key: 'actions', label: 'Acciones', thClass: 'd-none' },
      ],
      rolesDB: [],
      usuarioEstadosDB: [],
      delegacionesDB: [],
      options: {
        block: {
          blocks: [2, 8, 1],
          uppercase: true,
        },
        customDelimiter: {
          delimiters: ['-', '-'],
          blocks: [2, 8, 1],
          uppercase: true,
          numericOnly: true,
        },
      },
    }
  },
  computed: {
    isEditable() {
      return (this.openMode === 'new' || this.openMode === 'edit' || this.editarEnableDisable)
    },
    ...mapGetters(
      {
        permisosVer: 'getPermisosVer',
        permisosDel: 'getPermisosDel',
        permisosIns: 'getPermisosIns',
        permisosUpd: 'getPermisosUpd',
      },
    ),
  },
  created() {
    this.preloadedRoles = this.$store.getters.getRoles
    this.preloadedUsuarioEstado = this.$store.getters.getUsuarioEstado
    this.preloadedDelegacion = this.$store.getters.getDelegacion
    this.usuarioDto = JSON.parse(JSON.stringify(this.usuario))
    this.originalUsuarioDto = JSON.parse(JSON.stringify(this.usuario))
    this.rolesDB = this.preloadedRoles
    this.usuarioEstadosDB = this.preloadedUsuarioEstado
    this.delegacionesDB = this.preloadedDelegacion
    this.roles()
    this.delegaciones()
  },
  methods: {
    actionUsuario() {
      this.isWorking = true
      this.overlayConfig.message = 'Guardando cambios'
      if (this.usuarioDto.id !== null) {
        usuariosResource().updateUsuario(this.usuarioDto)
          .then(resultPutUpdateUsuario => {
            this.usuarioDto = resultPutUpdateUsuario
            // this.originalUsuarioDto = JSON.parse(JSON.stringify(this.usuarioDto))
          }).catch(error => {
            if (error.data && error.data.exception && error.data.exception.length > 0) {
              error.data.exception.forEach(e => {
                this.$toast({
                  component: ToastificationContent,
                  position: 'top-right',
                  props: {
                    title: e,
                    icon: 'AlertTriangleIcon',
                    variant: 'danger',
                    text: 'Error al modificar!',
                  },
                })
              })
            }
          })
          .finally(() => {
            this.isWorking = false
            this.overlayConfig.message = ''
          })
      } else {
        this.overlayConfig.message = 'Guardando nuevo usuario'
        usuariosResource().saveUsuario(this.usuarioDto)
          .then(resultPostSaveUsuario => {
            this.usuarioDto = resultPostSaveUsuario
            // this.originalUsuarioDto = JSON.parse(JSON.stringify(this.usuarioDto))
            this.backUsuario()
          }).catch(error => {
            if (error.data && error.data.exception && error.data.exception.length > 0) {
              error.data.exception.forEach(e => {
                this.$toast({
                  component: ToastificationContent,
                  position: 'top-right',
                  props: {
                    title: e,
                    icon: 'AlertTriangleIcon',
                    variant: 'danger',
                    text: 'Error al guardar!',
                  },
                })
              })
            }
          })
          .finally(() => {
            this.isWorking = false
            this.overlayConfig.message = ''
          })
      }
    },
    delegaciones() {
      this.listaDelegaciones = this.listaDelegaciones.concat(this.delegacionesDB)
    },
    roles() {
      this.cargandoRoles = true
      if (this.openMode !== 'new') {
        this.setCheckBoxRoles()
      } else {
        this.listaRoles = this.rolesDB
      }
    },
    actualizarRol(item) {
      if (item.selected) {
        this.usuarioDto.roles.push(item)
      } else if (this.usuarioDto.roles) {
        this.usuarioDto.roles.splice(this.usuarioDto.roles.findIndex(rol => rol.id === item.id), 1)
      }
    },
    setCheckBoxRoles() {
      const arrayCustom = []

      this.rolesDB.forEach(rol => {
        const rolCustom = {}
        rolCustom.id = rol.id
        rolCustom.nombre = rol.nombre
        rolCustom.codigo = rol.codigo
        rolCustom.descripcion = rol.descripcion

        if (this.usuarioDto.roles.findIndex(rolUser => rolUser.id === rol.id) !== -1) {
          rolCustom.selected = true
        } else {
          rolCustom.selected = false
        }

        arrayCustom.push(rolCustom)
      })
      this.listaRoles = arrayCustom
    },
    validationFormUsuario() {
      this.$refs.usuarioGenerico.validate().then(success => {
        if (success) {
          this.actionUsuario()
        }
      })
    },
    backUsuario() {
      this.$router.push('/usuarios')
      /*
      if (JSON.stringify(this.usuarioDto) === JSON.stringify(this.originalUsuarioDto)) {
        this.$router.push('/usuarios')
      } else {
        this.$swal({
          title: 'Cambios pendientes',
          text: '¿Deseas salir de todas maneras?',
          icon: 'warning',
          showCancelButton: true,
          confirmButtonText: 'Si',
          cancelButtonText: 'No',
          customClass: {
            confirmButton: 'btn btn-primary',
            cancelButton: 'btn btn-danger',
          },
          buttonsStyling: false,
        }).then(result => {
          if (result.value) {
            this.$router.push('/usuarios')
          }
        })
      } */
    },
  },
}
</script>
